import React from 'react';
import './TablePagination.css';
import ReactPaginate from 'react-paginate';

const TablePagination = ({ pagination, setPage, page, isInvite = false }) => {
    return (
      <div className="tf pagination-container">
        <div className="div-block-289">
          {isInvite ? (
            <div className="info-3">
              Showing{' '}
              {pagination?.currentPage * pagination?.pageSize +
                1 -
                pagination?.pageSize}{' '}
              to{' '}
              {pagination?.totalItems < pagination?.pageSize
                ? pagination?.totalItems
                : pagination?.currentPage * pagination?.pageSize >
                    pagination?.totalItems
                  ? pagination?.totalItems
                  : pagination?.currentPage * pagination?.pageSize}{' '}
              of {pagination?.totalItems}
            </div>
          ) : (
            <div className="info-3">
              Showing{' '}
              {pagination?.currentPage * pagination?.perPage +
                1 -
                pagination?.perPage}{' '}
              to{' '}
              {pagination?.totalItems < pagination?.perPage
                ? pagination?.totalItems
                : pagination?.currentPage * pagination?.perPage >
                    pagination?.totalItems
                  ? pagination?.totalItems
                  : pagination?.currentPage * pagination?.perPage}{' '}
              of {pagination?.totalItems}
            </div>
          )}

          <ReactPaginate
            pageCount={pagination?.totalPages}
            pageRangeDisplayed={2}
            breakLabel="..."
            nextLabel=">"
            previousLabel="<"
            onPageChange={({ selected }) => {
              setPage(selected + 1);
            }}
            forcePage={page - 1}
            containerClassName={'pagination'}
            previousClassName={'arrow'}
            previousLinkClassName={'fa_icon fa-arrow'}
            nextClassName={'arrow'}
            nextLinkClassName={'fa_icon fa-arrow'}
          />
        </div>
      </div>
    );
};

export default TablePagination;

import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { NavLink, useLocation } from "react-router-dom";
import $ from "jquery";
import { useDebounce } from "../../hooks/useDebounce";

const Sidebar = () => {
    const [activePage, setActivePage] = useState("");
    const location = useLocation();
    const [clickedIndex, setClickedIndex] = useState(null);

    const handleMenuClick = (index) => {
        setClickedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const handleSubMenuClick = () => {
        $('.submenu-item.web-sub a').on('click', function() {
            const subMenusEl = $(this).closest(".submenu-item.web-sub").find('.submenu.w_menu');
            if (subMenusEl.is(":visible")) {
                subMenusEl.hide();
            } else {
                subMenusEl.show();
            }
        });
    };

    useDebounce(handleSubMenuClick, 300, []);

    useEffect(() => {
        const submenuItems = document.querySelectorAll('.submenu .w_menu');

        submenuItems.forEach(item => {
            item.addEventListener('click', () => {
                submenuItems.forEach(submenuItem => {
                    submenuItem.classList.remove('active');
                });
                item.classList.add('active');
            });
        });
    }, []);

    useEffect(() => {
        const pathname = location.pathname.substring(1).toUpperCase();
        setActivePage(pathname);
    }, [location]);

    const isAuthoringEnabled = process.env.REACT_APP_IBL_ENABLE_AUTHORING_MENU !== "false";

    const menuItems = [
        {
            name: "overview",
            icon: "",
            label: "AUDIENCE",
            submenu: [
                { label: "REGISTERED USERS", page: "REGISTERED USERS", url: "/audience/registered-users" },
                { label: "ACTIVE USERS", page: "ACTIVE USERS", url: "/audience/active-users" },
                { label: "ENROLLMENTS", page: "ENROLLMENTS", url: "/audience/enrollments" },
                { label: "UNENROLLMENTS", page: "UNENROLLMENTS", url: "/audience/unenrollments" }
            ]
        },
        {
            name: "studio",
            icon: "",
            label: "STUDIO",
            submenu: [
                ...(isAuthoringEnabled ? [{
                    label: "AUTHORING",
                    submenu: [
                        { label: "Courses", page: "Courses", url: "/studio/authoring/courses" },
                        { label: "Programs", page: "Programs", url: "/studio/authoring/programs" }
                    ]
                }] : []),
                { label: "SETTINGS", page: "Settings", url: "/studio/settings" }
            ],
        },
        {
            name: "engagement",
            icon: "",
            label: "ENGAGEMENT",
            submenu: [
                { label: "COURSE COMPLETION", page: "COURSE COMPLETION", url: "/engagement/course-completion" },
                { label: "TIME SPENT", page: "TIME SPENT", url: "/engagement/time-spent" },
            ]
        },
        {
            name: "performance",
            icon: "",
            label: "PERFORMANCE",
            submenu: [
                { label: "GRADING", page: "GRADING", url: "/performance/grading" },
                { label: "CREDENTIALS", page: "CREDENTIALS", url: "/performance/credentials" }
            ]
        },
        {
            name: "per-learner",
            icon: "",
            label: "PER LEARNER",
            submenu: [
                { label: "LEARNERS", page: "Learners", url: "/per-learner/learners" },
            ]
        }
    ];

    return (
        <div className="sidebar">
            <div className="wrapper">
                <div data-name="overview" className="menu-wr">
                    <NavLink to="/" className="link-block-19 w-inline-block">
                        <div className="menu-option">
                            <div className="menu-icon">
                                <img
                                    src="https://assets-global.website-files.com/657af3ad1367da03b3b12527/657af3ad1367da03b3b1253e_62b33c1182cd9cbdcc8a0da1_62b1ddfcac0d91564ba2a3eb_ibl_logo.png"
                                    loading="lazy"
                                    alt=""
                                    className="icon"
                                />
                                <img
                                    src="https://assets-global.website-files.com/657af3ad1367da03b3b12527/657af3ad1367da03b3b12536_62bc49316fcb819a85489090_62b33c1182cd9cbdcc8a0da1_62b1ddfcac0d91564ba2a3eb_ibl_logo.png"
                                    loading="lazy"
                                    alt=""
                                    className="icon_h"
                                />
                            </div>
                            <div>
                                <div className="text-block-107">OVERVIEW</div>
                            </div>
                        </div>
                    </NavLink>
                </div>

                {menuItems.map((menuItem, index) => (
                    <div className="menu-wr" key={index}>
                        <a
                            className="link-block-13 w-inline-block"
                            data-name={menuItem.name}
                            href="#"
                            onClick={() => handleMenuClick(index)}
                        >
                            <div className="menu-option">
                                <div className="menu-icon">
                                    <div className="text-block fa_icon">{menuItem.icon}</div>
                                </div>
                                <div>
                                    <div className="text-block-107">{menuItem.label}</div>
                                </div>
                                <div className="submenu_arrow">
                                    <div className="text-block fa_icon up_arrow"></div>
                                    <div
                                        className={`text-block fa_icon down_arrow ${clickedIndex === index ? 'active' : ''}`}>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <div className={`submenu ${clickedIndex === index ? 'clicked' : ''}`}>
                            {menuItem.submenu.map((subItem, subIndex) => (
                                <React.Fragment key={subIndex}>
                                    {subItem.label && !subItem.submenu && (
                                        <div className={`submenu-item ${activePage === subItem.page ? 'active' : ''}`}
                                             key={subIndex}>
                                            <NavLink className="link-block-12 w-inline-block" to={subItem.url}>
                                                <div className="text-block-108">{subItem.label}</div>
                                            </NavLink>
                                        </div>
                                    )}
                                    {subItem.submenu && (
                                        <div className="submenu-item web-sub">
                                            <a href="#" className="link-block-12 w_sb w-inline-block">
                                                <div className="text-block-108">{subItem.label}</div>
                                                <div className="text-block fa_icon down_arrow"></div>
                                            </a>
                                            {
                                                Array.isArray(subItem?.submenu) && subItem?.submenu.length > 0 &&
                                                <div className="submenu w_menu">
                                                    {subItem?.submenu?.map((subMenuItem, index) => (
                                                        <div key={index}>
                                                            <div className="submenu-item web-sub">
                                                                <NavLink to={subMenuItem.url}
                                                                         className="link-block-12 w-inline-block">
                                                                    <div
                                                                        className="text-block-108">{subMenuItem.label}</div>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Sidebar;

import Graphfilter from '../Graphfilter/Graphfilter';
import Descriptivelinechart from '../Descriptivelinechart/Descriptivelinechart';
import Linechartgraph from '../Linechartgraph/Linechartgraph';
import Horizontalbarchart from '../Horizontalbarchart/Horizontalbarchart';
import Predictivedatepills from '../Predictivedatepills/Predictivedatepills';
import Descriptivedatepills from '../Descriptivedatepills/Descriptivedatepills';
import Predictivelinechart from '../Predictivelinechart/Predictivelinechart';
import React, { useEffect, useState } from 'react';
import './Registeredusers.css';
import useRegisteredUsers from '../../hooks/useRegisteredUsers';
import DescriptiveLineChartGraph from '../DescriptiveLineChartGraph/DescriptiveLineChartGraph';
import Verticalgraphcard from '../Verticalgraphcard/Verticalgraphcard';
import {
  randomLineChartGraphData,
  randomVerticalGraphData,
} from '../../hooks/helpers';

const Registeredusers = (props) => {
  const {
    registeredUsersPerCourse,
    registeredUsersPerCourseLoaded,
    registeredUsersOverTime,
    registeredUsersOverTimeLoaded,
  } = useRegisteredUsers();
  return (
    <div
      className="p-row r-r"
      react-hook="useRegisteredUsers"
      react-hook-return="registeredUsersOverTimeTotal,registeredUsersOverTimeLoaded,registeredUsersOverTimeChangeClass,registeredUsersOverTimeChangeSign,registeredUsersOverTimeNumericalChange,registeredUsersOverTimePercentageChange,registeredUsersOverTimeChangeSymbol,registeredUsersOverTimeChartData,predictedRegisteredUsersTotal,predictedRegisteredUsersLoaded,predictedRegisteredUsersChangeClass,predictedRegisteredUsersChangeSign,predictedRegisteredUsersNumericalChange,predictedRegisteredUsersPercentageChange,predictedRegisteredUsersChangeSymbol,predictedRegisteredUsersChartData,registeredUsersPerCourseTotal,registeredUsersPerCourseLoaded,registeredUsersPerCourseChangeClass,registeredUsersPerCourseChangeSign,registeredUsersPerCourseNumericalChange,registeredUsersPerCoursePercentageChange,registeredUsersPerCourseChangeSymbol,registeredUsersPerCoursetData,predictedUsersPerCourseTotal,predictedUsersPerCourseLoaded,predictedUsersPerCourseChangeClass,predictedUsersPerCourseChangeSign,predictedUsersPerCourseNumericalChange,predictedUsersPerCoursePercentageChange,predictedUsersPerCourseChangeSymbol,predictedUsersPerCoursetData"
    >
      <div className="div-block-204 l r-r" style={{ width: '100%' }}>
        <DescriptiveLineChartGraph
          data={registeredUsersOverTime}
          dataLoaded={registeredUsersOverTimeLoaded}
          title={'Registered Users Over Time'}
          index={1}
        ></DescriptiveLineChartGraph>
        <div className="table_cont hidden">
          <div className="div-block-202 w_t">
            <div className="div-block-169">
              <div>
                <div className="text-block-109">
                  Registered Users per Course
                  <br />
                </div>
              </div>
            </div>
            <div className="div-block-169">
              <div className="div-block-171">
                <div className="sm">Show</div>
                <div className="form-block w-form">
                  <form
                    data-name="Email Form 2"
                    data-wf-element-id="e6174913-3549-8f42-1990-4a937802e7c3"
                    data-wf-page-id="657af3ad1367da03b3b1262f"
                    id="email-form-2"
                    method="get"
                    name="email-form-2"
                  >
                    <select
                      className="show w-select"
                      data-name="Field 2"
                      id="field-2"
                      name="field-2"
                    >
                      <option value="">10</option>
                      <option value="First">25</option>
                      <option value="Second">50</option>
                      <option value="Third">100</option>
                    </select>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
                <div className="sm">entries</div>
              </div>
              <div className="div-block-171">
                <div className="sm">Search:</div>
                <div className="form-block w-form">
                  <form
                    data-name="Email Form 2"
                    data-wf-element-id="e6174913-3549-8f42-1990-4a937802e7d1"
                    data-wf-page-id="657af3ad1367da03b3b1262f"
                    id="email-form-2"
                    method="get"
                    name="email-form-2"
                  >
                    <input
                      className="input w-input"
                      data-name="Field 3"
                      id="field-3"
                      maxLength={256}
                      name="field-3"
                      placeholder=""
                      required=""
                      type="text"
                    />
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table">
              <div className="th">
                <div className="tc">
                  <div className="sm">#</div>
                </div>
                <div className="tc">
                  <div className="w_sort">
                    <div className="text-block-134 fa_icon sort"></div>
                    <div className="sm">Course</div>
                  </div>
                </div>
                <div className="tc">
                  <div className="w_sort">
                    <div className="text-block-134 fa_icon sort"></div>
                    <div className="sm">Registered Users</div>
                  </div>
                </div>
                <div className="tc">
                  <div className="w_sort">
                    <div className="text-block-134 fa_icon sort"></div>
                    <div className="sm">Percentage</div>
                  </div>
                </div>
              </div>
              <div className="tr">
                <div className="tc tcell">
                  <div className="m">1</div>
                </div>
                <div className="tc tcell">
                  <a className="tl-2 np" href="#">
                    English
                  </a>
                </div>
                <div className="tc tcell">
                  <div className="m">22131</div>
                </div>
                <div className="tc tcell">
                  <div className="w_per">
                    <div className="p_text">43% </div>
                    <div className="div-block-177">
                      <div className="div-block-203"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tr">
                <div className="tc tcell">
                  <div className="m">2</div>
                </div>
                <div className="tc tcell">
                  <a className="tl-2 np" href="#">
                    Physics
                  </a>
                </div>
                <div className="tc tcell">
                  <div className="m">11521</div>
                </div>
                <div className="tc tcell">
                  <div className="w_per">
                    <div className="p_text">57% </div>
                    <div className="div-block-177">
                      <div className="div-block-203 ph"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tf">
                <div className="div-block-176">
                  <div className="info-2">Showing 1 to 2 of 2 entries</div>
                  <a className="tl-2" href="#">
                    1
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Verticalgraphcard
          data={registeredUsersPerCourse}
          dataLoaded={registeredUsersPerCourseLoaded}
          index={1}
          title={'Registered Users per Course'}
          label={'Registered Users'}
        ></Verticalgraphcard>
      </div>
      {/*<div className="div-block-204 r rr">
        <DescriptiveLineChartGraph
            data={randomLineChartGraphData()}
            dataLoaded={tempLoadedState}
            title={"Predicted Users Over Time"}
            index={2}
            isPredictive={true}
        ></DescriptiveLineChartGraph>
        <div className="table_cont hidden">
          <div className="div-block-202 prt">
            <div className="div-block-169">
              <div>
                <div className="text-block-109 sp">
                  Predicted Users per Course
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="div-block-169">
              <div className="div-block-171">
                <div className="sm">Show</div>
                <div className="form-block w-form">
                  <form
                    data-name="Email Form 2"
                    data-wf-element-id="e6174913-3549-8f42-1990-4a937802e86e"
                    data-wf-page-id="657af3ad1367da03b3b1262f"
                    id="email-form-2"
                    method="get"
                    name="email-form-2"
                  >
                    <select
                      className="show w-select"
                      data-name="Field 2"
                      id="field-2"
                      name="field-2"
                    >
                      <option value="">10</option>
                      <option value="First">25</option>
                      <option value="Second">50</option>
                      <option value="Third">100</option>
                    </select>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
                <div className="sm">entries</div>
              </div>
              <div className="div-block-171">
                <div className="sm">Search:</div>
                <div className="form-block w-form">
                  <form
                    data-name="Email Form 2"
                    data-wf-element-id="e6174913-3549-8f42-1990-4a937802e87c"
                    data-wf-page-id="657af3ad1367da03b3b1262f"
                    id="email-form-2"
                    method="get"
                    name="email-form-2"
                  >
                    <input
                      className="input w-input"
                      data-name="Field 3"
                      id="field-3"
                      maxLength={256}
                      name="field-3"
                      placeholder=""
                      required=""
                      type="text"
                    />
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table">
              <div className="th">
                <div className="tc">
                  <div className="sm">#</div>
                </div>
                <div className="tc">
                  <div className="w_sort">
                    <div className="text-block-134 fa_icon sort"></div>
                    <div className="sm">Course</div>
                  </div>
                </div>
                <div className="tc">
                  <div className="w_sort">
                    <div className="text-block-134 fa_icon sort"></div>
                    <div className="sm">Predicted Users</div>
                  </div>
                </div>
                <div className="tc">
                  <div className="w_sort">
                    <div className="text-block-134 fa_icon sort"></div>
                    <div className="sm">Percentage of All Courses</div>
                  </div>
                </div>
              </div>
              <div className="tr">
                <div className="tc tcell">
                  <div className="m">1</div>
                </div>
                <div className="tc tcell">
                  <a className="tl-2 np prb" href="#">
                    English
                  </a>
                </div>
                <div className="tc tcell">
                  <div className="m">25414</div>
                </div>
                <div className="tc tcell">
                  <div className="w_per">
                    <div className="p_text">43% </div>
                    <div className="div-block-177">
                      <div className="div-block-203 prb"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tr">
                <div className="tc tcell">
                  <div className="m">2</div>
                </div>
                <div className="tc tcell">
                  <a className="tl-2 np prb" href="#">
                    Physics
                  </a>
                </div>
                <div className="tc tcell">
                  <div className="m">23103</div>
                </div>
                <div className="tc tcell">
                  <div className="w_per">
                    <div className="p_text">57% </div>
                    <div className="div-block-177">
                      <div className="div-block-203 ph prb"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tf">
                <div className="div-block-176">
                  <div className="info-2">Showing 1 to 2 of 2 entries</div>
                  <a className="tl-2 prb" href="#">
                    1
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Verticalgraphcard
            data={randomVerticalGraphData()}
            dataLoaded={tempLoadedState}
            index={2}
            title={"Predicted Users per Course"}
            label={"Registered Users"}
            isPredictive={true}
        ></Verticalgraphcard>
      </div>*/}
    </div>
  );
};

export default Registeredusers;

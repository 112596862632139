import Timespentpercourserow from "../Timespentpercourserow/Timespentpercourserow";
import Descriptivelinechart from "../Descriptivelinechart/Descriptivelinechart";
import Table from "../Table/Table";
import Timespentpercoursepagination from "../Timespentpercoursepagination/Timespentpercoursepagination";
import Linechartgraph from "../Linechartgraph/Linechartgraph";
import Coursetable from "../Coursetable/Coursetable";
import Predictivedatepills from "../Predictivedatepills/Predictivedatepills";
import Descriptivedatepills from "../Descriptivedatepills/Descriptivedatepills";
import Predictivelinechart from "../Predictivelinechart/Predictivelinechart";
import React, {useEffect, useState} from "react";
import "./Timespent.css";
import useTimeSpent from "../../hooks/useTimeSpent";
import DescriptiveLineChartGraph from "../DescriptiveLineChartGraph/DescriptiveLineChartGraph";
import Timespenttable from "../Timespenttable/Timespenttable";
import {randomLineChartGraphData} from "../../hooks/helpers";
import {getEngagementTimeSpentOvertimeAPI, getEngagementTimeSpentPerCourseAPI} from "../../hooks/utils/timeSpent";

const Timespent = (props) => {
  const [
    engagementTimeSpentPerCourse,
    engagementTimeSpentPerCourseLoaded,
    engagementTimeSpentOvertime,
    engagementTimeSpentOvertimeLoaded,
  ] = useTimeSpent();

  /*const [tempLoadedState, setTempLoadedState] = useState(false)

  useEffect(() => {
    setTimeout(()=>{
      setTempLoadedState(true)
    }, 3000)
  }, []);*/
  return (
    <div
      react-hook="useTimeSpent"
      react-hook-return="timeSpentTotal,timeSpentLoaded,timeSpentChangeClass,timeSpentChangeSign,timeSpentNumericalChange,timeSpentPercentageChange,timeSpentChangeSymbol,timeSpentChartData,predictedTimeCompletionTotal,predictedTimeCompletionLoaded,predictedTimeCompletionChangeClass,predictedTimeCompletionChangeSign,predictedTimeCompletionNumericalChange,predictedTimeCompletionPercentageChange,predictedTimeCompletionChangeSymbol,predictedTimeCompletionChartData,timeSpentPerCourseCompletionSelect,timeSpentPerCourseCompletionSearch,timeSpentPerCourseCompletionTableData,timeSpentPerCourseCompletionCurrentPage,timeSpentPerCourseCompletionItemsPerPage,timeSpentPerCourseCompletionTotalItems"
    >
      <div className="p-row r-r">
        <div className="div-block-204 l" style={{width:"100%"}}>
          <DescriptiveLineChartGraph
              data={engagementTimeSpentOvertime}
              dataLoaded={engagementTimeSpentOvertimeLoaded}
              title={"Time Spent"}
              index={1}
              convertTime={true}
          />
        </div>
        {/*<div className="div-block-204 r">
          <DescriptiveLineChartGraph
              data={randomLineChartGraphData()}
              dataLoaded={tempLoadedState}
              title={"Predicted Time"}
              index={2}
              isPredictive={true}
          />
        </div>*/}
      </div>
      <Timespenttable endPoint={getEngagementTimeSpentPerCourseAPI} title={"Time Spent Per Course"}></Timespenttable>

    </div>
  );
};

export default Timespent;

import React from "react";
import Descriptivepagetitle from "../../../components/Descriptivepagetitle/Descriptivepagetitle";
import Registeredusers from "../../../components/Registeredusers/Registeredusers";
import "./RegisteredUsersViewContent.css"

const RegisteredUsersViewContent = () => {
  return (
      <>
        <div className="p-row r-r">
          <div className="div-block-204 l r-r">
            <div className="div-block-183">
              <Descriptivepagetitle title={"DESCRIPTIVE DATA"}></Descriptivepagetitle>
            </div>
          </div>
          {/*<div className="div-block-204 r rr">
            <div className="div-block-183">
                <Descriptivepagetitle title={"PRESCRIPTIVE DATA"} isPredictive={true}></Descriptivepagetitle>
            </div>
          </div>*/}
        </div>
        <Registeredusers></Registeredusers>
      </>
  );
};

export default RegisteredUsersViewContent;

import React, { useContext, useEffect, useState } from 'react';
import './Downloadreportcard.css';
import { createNewReport } from '../../hooks/utils/downloads';
import { AppDataContext, IBLSpinLoader } from '@iblai/ibl-web-react-common';

const Downloadreportcard = ({ icon, display_name, report_name, status }) => {
  const { appData, setAppData } = useContext(AppDataContext);
  const [reportStatus, setReportStatus] = useState('');
  const [reportStatusData, setReportStatusData] = useState({});

  const handleDownloadReport = () => {
    setReportStatus('pending');
    createNewReport(
      {
        report_name,
        owner: appData?.userData?.user_nicename,
      },
      ({ data }) => {
        if (data?.url) {
          setReportStatusData(data);
          setReportStatus(data?.state || '');
        } else {
          setTimeout(() => {
            setAppData({
              ...appData,
              downloadsRefetching: true,
            });
          }, 8000);
        }
      }
    );
  };

  useEffect(() => {
    setReportStatus(status?.state || '');
    setReportStatusData(status);
  }, [status]);

  const isReportExpired = () => {
    if (reportStatusData?.expires) {
      const expiryDate = new Date(reportStatusData?.expires);
      const currentDate = new Date();
      return currentDate > expiryDate;
    }
    return false;
  };

  return (
    <div
      className="d_section download-report-section"
      map=""
      map-value="report"
    >
      <div className="table_cont-2">
        <div className="sm_card-3 dpcard">
          <div className="div-block-169 dp">
            <div className="div-block-181 d4">
              <img
                alt=""
                className="image-36"
                loading="lazy"
                src="images/book_1book.png"
              />
            </div>
            <div className="text-block-138 dpc">
              {display_name || '-'}
              <br />
            </div>
            {reportStatus !== 'pending' && (
              <div
                className="div-block-256 generate-report"
                prop-events-value-onclick="handleReportBtnClick"
                onClick={handleDownloadReport}
              >
                <div>Generate New Report</div>
              </div>
            )}
            {reportStatus === 'completed' && !isReportExpired() && (
              <div
                className="div-block-256 export_to_csv"
                style={{ display: 'block' }}
              >
                <a
                  href={reportStatusData?.url || '#'}
                  target="_blank"
                  previewlistener="true"
                >
                  <div>Download Existing Report</div>
                </a>
              </div>
            )}
            {reportStatus === 'pending' && (
              <div className="report-spinner">
                <IBLSpinLoader size={20} color={'#FFF'} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Downloadreportcard;

import React, {useRef, useState} from 'react';
import './Header.css';
import {Link, NavLink} from 'react-router-dom';
import {defaultSPALogoData, getSkillsURL} from '../../hooks/helpers';
import {IBLSpinLoader} from '@iblai/ibl-web-react-common';
import Logo from '../Logo/Logo';
import SearchBar from "../SearchBar/SearchBar";
import {IBLUserProfile} from '@iblai/ibl-web-react-common'
import {CiGlobe} from "react-icons/ci";
import Usermenu from "../Usermenu/Usermenu";

const skillsURL = getSkillsURL();

const Header = (props) => {
    return (
        <div className="nav">
            <div className="div-block">
                <NavLink
                    className="link-block-17 w-inline-block"
                    to="/"
                    prop-events-value-onclick="handleHeaderLogoClick"
                >
                    <Logo/>
                </NavLink>
                <div className="div-block-3">
                    <div className="s-input">
                        <SearchBar/>
                    </div>
                    <div className="div-block-2">
                        <a
                            className="ai-skills-btn w-inline-block"
                            href={skillsURL}
                            prop-events-value-onclick="handleAISkillsBtnClick"
                        >
                            <div className="text-block-130">AI Skills</div>
                        </a>
                    </div>
                    <div className="div-block-2">
                        <NavLink
                            to="/invites"
                            className="link-block-18 w-inline-block"
                            prop-events-value-onclick="handleDownloadBtnClick"
                        >
                            <div className="text-block-130">Invites</div>
                        </NavLink>
                    </div>
                    <div className="div-block-2">
                        <NavLink
                            to="/downloads"
                            className="link-block-18 w-inline-block"
                            prop-events-value-onclick="handleDownloadBtnClick"
                        >
                            <div className="text-block-130">Downloads</div>
                        </NavLink>
                    </div>
                    <div className="div-block-2 hidden">
                        <div className="notifications_trigger">
                            <a
                                className="link-block-18 w-inline-block"
                                href="notifications.html"
                            >
                                <div className="text-block-130">Notifications</div>
                            </a>
                        </div>
                    </div>
                    <div className="div-block-2 nav-field hidden">
                        <div className="roles">
                            <img
                                alt=""
                                className="user-img nb"
                                loading="lazy"
                                src="images/setting_1setting.png"
                            />
                            <div className="text-block-130 sp">Roles</div>
                            <div className="text-block fa_icon arrow"></div>
                            <div className="div-block-164 profile">
                                <div className="div-block-165">
                                    <div className="text-block-104">Ashley Miller</div>
                                    <div className="text-block-104 em">ashley@gmail.com</div>
                                </div>
                                <Link className="link-block-11 w-inline-block" to="#">
                                    <div className="text-block-106">Overview</div>
                                </Link>
                                <Link className="link-block-11 w-inline-block" to="#">
                                    <div className="text-block-106">Profile</div>
                                </Link>
                                <Link className="link-block-11 w-inline-block" to="#">
                                    <div className="text-block-106">Log Out</div>
                                </Link>
                            </div>
                            <div className="roles_popup">
                                <div className="div-block-195">
                                    <Link className="link-2" to="#">
                                        Admin
                                    </Link>
                                </div>
                                <div className="div-block-195">
                                    <Link className="link-2" to="#">
                                        Instructor
                                    </Link>
                                </div>
                                <div className="div-block-195 last">
                                    <Link className="link-2" to="#">
                                        Editor
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Usermenu></Usermenu>
                </div>
                <div className="mobile_nav_trigger">
                    <div className="text-block fa_icon"></div>
                </div>
            </div>
            <div className="mobile_menu">
                <div className="div-block-3 mob">
                    <div className="div-block-2 nav-searchh">
                        <div className="text-block fa_icon"></div>
                    </div>
                    <div className="div-block-2 nav-field">
                        <div className="form-block w-form">
                            <form
                                data-name="Email Form"
                                data-wf-element-id="f19f4444-286e-d7ee-ce71-456a79932a8b"
                                data-wf-page-id="657af3ad1367da03b3b1262f"
                                id="email-form"
                                method="get"
                                name="email-form"
                            >
                                <div>
                                    <select
                                        className="select-field w-select"
                                        data-name="Field 4"
                                        id="field-4"
                                        name="field-4"
                                    >
                                        <option value="">All Users</option>
                                        <option value="First">First choice</option>
                                        <option value="Second">Second choice</option>
                                        <option value="Third">Third choice</option>
                                    </select>
                                </div>
                            </form>
                            <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                    </div>
                    <div className="div-block-2">
                        <div className="profile_menu_trigger">
                            <img
                                alt=""
                                className="image-4"
                                loading="lazy"
                                src="images/cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1-1.jpeg"
                            />
                            <div className="text-block fa_icon arrow"></div>
                            <div className="div-block-164 profile">
                                <div className="div-block-165">
                                    <div className="text-block-104">Ashley Miller</div>
                                    <div className="text-block-104 em">ashley@gmail.com</div>
                                </div>
                                <Link className="link-block-11 w-inline-block" to="#">
                                    <div className="text-block-106">Overview</div>
                                </Link>
                                <Link className="link-block-11 w-inline-block" to="#">
                                    <div className="text-block-106">Profile</div>
                                </Link>
                                <Link className="link-block-11 w-inline-block" to="#">
                                    <div className="text-block-106">Log Out</div>
                                </Link>
                            </div>
                        </div>
                        <div className="prof_menu_popup">
                            <div className="div-block-195">
                                <Link className="link-2" to="#">
                                    My Profile
                                </Link>
                            </div>
                            <div className="div-block-195">
                                <Link className="link-2" to="#">
                                    Groups of Learners
                                </Link>
                            </div>
                            <div className="div-block-195 last">
                                <Link className="link-2" to="#">
                                    Log Out
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;

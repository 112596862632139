import React from 'react';
import { actionTypes } from '../../../views/content/InvitationsViewContent/InvitationsReducer';

export const InvitationModeTab = ({ state, dispatch }) => {
  return (
    <div className="tabs-menu-2 w-tab-menu" role="tablist">
      <a
        data-w-tab="Individual"
        className={`invite-mode-tab-link w-inline-block w-tab-link  ${state.invitationsTab.activeInvitationMode === 'Single' ? 'w--current' : ''}`}
        id="w-tabs-1-data-w-tab-0"
        href="#"
        onClick={() => {
          dispatch({
            type: actionTypes.SET_FIELD,
            field: 'invitationsTab',
            value: { ...state.invitationsTab, activeInvitationMode: 'Single' },
          });
        }}
        role="tab"
        aria-controls="w-tabs-1-data-w-pane-0"
        aria-selected="true"
      >
        <div className="w-layout-hflex flex-block-13">
          <img
            src="/images/user-12.svg"
            loading="lazy"
            alt=""
            className="image-142"
          />
        </div>
        <h5 className="heading-5">Single Invite</h5>
      </a>
      <a
        data-w-tab="Bulk"
        className={`invite-mode-tab-link w-inline-block w-tab-link  ${state.invitationsTab.activeInvitationMode === 'Bulk' ? 'w--current' : ''}`}
        id="w-tabs-1-data-w-tab-1"
        href="#"
        onClick={() => {
          dispatch({
            type: actionTypes.SET_FIELD,
            field: 'invitationsTab',
            value: { ...state.invitationsTab, activeInvitationMode: 'Bulk' },
          });
        }}
        role="tab"
        aria-controls="w-tabs-1-data-w-pane-1"
        aria-selected="false"
        tabIndex={-1}
      >
        <div className="w-layout-hflex flex-block-13">
          <img
            src="/images/mails.svg"
            loading="lazy"
            alt=""
            className="image-142"
          />
        </div>
        <h5 className="heading-5">Bulk Invite</h5>
      </a>
      <a
        data-w-tab="MultiSeat"
        className={`invite-mode-tab-link w-inline-block w-tab-link  ${state.invitationsTab.activeInvitationMode === 'Seats' ? 'w--current' : ''}`}
        id="w-tabs-1-data-w-tab-2"
        href="#"
        onClick={() => {
          dispatch({
            type: actionTypes.SET_FIELD,
            field: 'invitationsTab',
            value: { ...state.invitationsTab, activeInvitationMode: 'Seats' },
          });
        }}
        role="tab"
        aria-controls="w-tabs-1-data-w-pane-2"
        aria-selected="false"
        tabIndex={-1}
      >
        <div className="w-layout-hflex flex-block-13">
          <img
            src="/images/users-4.svg"
            loading="lazy"
            alt=""
            className="image-142"
          />
        </div>
        <h5 className="heading-5">Seats Invite</h5>
      </a>
    </div>
  );
};

import React from 'react';
import {
  getCourseInvitations,
  getPlatformInvitations, getProgramInvitation,
} from '../../../hooks/utils/invitations';
import { actionTypes } from '../../../views/content/InvitationsViewContent/InvitationsReducer';

const invitationFunctionMapping = {
  Platform: getPlatformInvitations,
  Course: getCourseInvitations,
  Program: getProgramInvitation,
};
const handleDropdown = (e, state, dispatch, reInitializeInvitationData) => {
  dispatch({
    type: actionTypes.SET_FIELD,
    field: 'activeTableInvitationType',
    value: e.target.value,
  });
  invitationFunctionMapping[e.target.value](
    (data) => {
      reInitializeInvitationData(data);
    },
    state.platformInvitationMetaData.pagination.pageSize,
    1
  );
};
export const InvitationsFilter = ({
  state,
  dispatch,
  reInitializeInvitationData,
}) => {
  return (
    <div className="div-block-171">
      <div className="sm">Show</div>
      <div className="form-block w-form">
        <form
          id="email-form-2"
          name="email-form-2"
          data-name="Email Form 2"
          method="get"
          data-wf-page-id="657af3ad1367da03b3b125d5"
          data-wf-element-id="4386085a-1eef-af03-c6b1-7a5a39f298eb"
        >
          <select
            id="field-2"
            name="field-2"
            data-name="Field 2"
            style={{ width: '90px' }}
            onChange={(e) =>
              handleDropdown(e, state, dispatch, reInitializeInvitationData)
            }
            className="show w-select"
          >
            <option value={'Platform'}>Platform</option>
            <option value={'Course'}>Course</option>
            <option value={'Program'}>Program</option>
          </select>
        </form>
      </div>
      <div className="sm">Invitations</div>
    </div>
  );
};

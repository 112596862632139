import Unenrollmentspercourserow from "../Unenrollmentspercourserow/Unenrollmentspercourserow";
import Unenrollmentspercoursepagination from "../Unenrollmentspercoursepagination/Unenrollmentspercoursepagination";
import Descriptivelinechart from "../Descriptivelinechart/Descriptivelinechart";
import Authoringcoursesselect from "../Authoringcoursesselect/Authoringcoursesselect";
import Table from "../Table/Table";
import Unenrollmentspercourse from "../Unenrollmentspercourse/Unenrollmentspercourse";
import Unenrollmentsovertime from "../Unenrollmentsovertime/Unenrollmentsovertime";
import Predictivedatepills from "../Predictivedatepills/Predictivedatepills";
import Predictedunenrollments from "../Predictedunenrollments/Predictedunenrollments";
import Descriptivedatepills from "../Descriptivedatepills/Descriptivedatepills";
import Predictivelinechart from "../Predictivelinechart/Predictivelinechart";
import React, {useEffect, useState} from "react";
import "./Unenrollments.css";
import useUnenrollments from "../../hooks/useUnenrollments";
import DescriptiveLineChartGraph from "../DescriptiveLineChartGraph/DescriptiveLineChartGraph";
import Coursetable from "../Coursetable/Coursetable";
import {randomLineChartGraphData} from "../../hooks/helpers";
import {getUnenrollmentsPerCourseAPI} from "../../hooks/utils/unenrollments";

const Unenrollments = (props) => {
  const [
    unenrollmentsOverTime,
    unenrollmentsOverTimeLoaded,
    /*unenrollmentsPerCourse,
    unenrollmentsPerCourseLoaded,*/
  ] = useUnenrollments();

  /*const [tempLoadedState, setTempLoadedState] = useState(false)

  useEffect(() => {
    setTimeout(()=>{
      setTempLoadedState(true)
    }, 3000)
  }, []);*/
  return (
    <div
      react-hook="useUnenrollments"
      react-hook-return="unenrollmentsOverTimeTotal,unenrollmentsOverTimeLoaded,unenrollmentsOverTimeChangeClass,unenrollmentsOverTimeChangeSign,unenrollmentsOverTimeNumericalChange,unenrollmentsOverTimePercentageChange,unenrollmentsOverTimeChangeSymbol,unenrollmentsOverTimeChartData,predictedunenrollmentsTotal,predictedunenrollmentsLoaded,predictedunenrollmentsChangeClass,predictedunenrollmentsChangeSign,predictedunenrollmentsNumericalChange,predictedunenrollmentsPercentageChange,predictedunenrollmentsChangeSymbol,predictedunenrollmentsChartData,unenrollmentsPerCourseSelect,unenrollmentsPerCourseSearch,unenrollmentsPerCourseTableData,unenrollmentsPerCourseCurrentPage,unenrollmentsPerCourseItemsPerPage,unenrollmentsPerCourseTotalItems"
    >
      <div className="p-row r-r">
        <div className="div-block-204 l" style={{width:"100%"}}>
          <DescriptiveLineChartGraph
              data={unenrollmentsOverTime}
              dataLoaded={unenrollmentsOverTimeLoaded}
              title={"Unenrollments Over Time"}
              index={1}
          />
        </div>
        {/*<div className="div-block-204 r">
          <DescriptiveLineChartGraph
              data={randomLineChartGraphData()}
              dataLoaded={tempLoadedState}
              title={"Predicted Unenrollments"}
              index={2}
              isPredictive={true}
          />
        </div>*/}
      </div>
      <Coursetable endPoint={getUnenrollmentsPerCourseAPI} title={"Unenrollments per Course"}></Coursetable>

    </div>
  );
};

export default Unenrollments;

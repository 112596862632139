import React from "react";
import Credentials from "../../../components/Credentials/Credentials";
import "./CredentialsViewContent.css"
import Descriptivepagetitle from "../../../components/Descriptivepagetitle/Descriptivepagetitle";

const CredentialsViewContent = () => {
    return (
        <>
          <div className="p-row r-r">
            <div className="div-block-204 l">
              <div className="div-block-183">
                  <Descriptivepagetitle title={"DESCRIPTIVE DATA"}/>
              </div>
            </div>
            {/*<div className="div-block-204 r">
              <div className="div-block-183">
                  <Descriptivepagetitle isPredictive={true} title={"PREDICTIVE DATA"}/>
              </div>
            </div>*/}
          </div>
          <Credentials></Credentials>
        </>

    )
}

export default CredentialsViewContent;
import React from "react";
import "./Descriptivepagetitle.css";

const Descriptivepagetitle = ({title="OVERVIEW AI", isPredictive=false}) => {
  return (
    <div className="div-block-205">
      <div className={`div-block-206 ${isPredictive ? "pr" : ""}`}></div>
      <div className="text-block-113" prop-pagetitle="DESCRIPTIVE DATA">
          {title}
      </div>
    </div>
  );
};

export default Descriptivepagetitle;

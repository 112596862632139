import TableSizeDropdown from '../../TableSizeDropdown/TableSizeDropdown';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { Link } from 'react-router-dom';
import EmptyTableDataInfographic from '../../EmptyTableDataInfographic/EmptyTableDataInfographic';
import TablePagination from '../../TablePagination/TablePagination';
import React from 'react';
import { getImageUrl } from '../../../hooks/helpers';
import { InvitationsFilter } from '../InvitationsFilter/InvitationsFilter';
import './InvitationsTable.css';

const imageUrl = getImageUrl('');

export const InvitationsTable = ({
  invitations,
  state,
  dispatch,
  invitationsLoaded,
  onRedeemClick,
  setPage,
  setSize,
  reInitializeInvitationData,
}) => {
  return (
    <div className="p-row over-row" is-react-component="PlatformInvitations">
      <div className="table_cont ns w_o full-w">
        <div className="div-block-202 w_bg nbg w_m_w">
          <div className="div-block-235">
            <div className="sm available">
              Available Invites:{' '}
              {state.platformInvitationMetaData.availableInvites}
            </div>
            <div className="sm available"></div>
          </div>

          <div className="div-block-169">
            <div className="div-block-171">
              <div className="sm">Show</div>
              <div className="form-block w-form">
                <TableSizeDropdown setSize={setSize} setPage={setPage} />
              </div>
              <div className="sm">entries</div>
            </div>
            <InvitationsFilter
              state={state}
              dispatch={dispatch}
              reInitializeInvitationData={reInitializeInvitationData}
            />
          </div>

          <div className="div-block-217 ovt n_w">
            <div
              className="table-container"
              style={{ width: '100%', overflowX: 'auto' }}
            >
              <div
                className="table"
                style={{ width: '100%', minWidth: '1200px' }}
              >
                <div className="th" style={{ whiteSpace: 'nowrap' }}>
                  <div className="tc _10">
                    <div className="sm">#</div>
                  </div>
                  <div className="tc _40">
                    <div className="w_sort">
                      <div className="text-block-134 fa_icon sort"></div>
                      <div className="sm">Username</div>
                    </div>
                  </div>
                  <div className="tc _40">
                    <div className="w_sort">
                      <div className="text-block-134 fa_icon sort"></div>
                      <div className="sm">Email</div>
                    </div>
                  </div>
                  <div className="tc _10">
                    <div className="w_sort">
                      <div className="text-block-134 fa_icon sort"></div>
                      <div className="sm">Active</div>
                    </div>
                  </div>
                  {state.activeTableInvitationType === 'Program' && (
                    <div className="tc _40">
                      <div className="w_sort">
                        <div className="text-block-134 fa_icon sort"></div>
                        <div className="sm">Program Key</div>
                      </div>
                    </div>
                  )}
                  {state.activeTableInvitationType === 'Course' && (
                    <div className="tc _40">
                      <div className="w_sort">
                        <div className="text-block-134 fa_icon sort"></div>
                        <div className="sm">Course ID</div>
                      </div>
                    </div>
                  )}
                  <div className="tc _40">
                    <div className="w_sort">
                      <div className="text-block-134 fa_icon sort"></div>
                      <div className="sm">Source</div>
                    </div>
                  </div>
                  <div className="tc _10"></div>
                </div>
                {!invitationsLoaded ? (
                  <IBLSpinLoader
                    color={'#FFF'}
                    size={20}
                    containerHeight={'200px'}
                  />
                ) : (
                  <>
                    {Array.isArray(invitations.results) &&
                    invitations.results.length > 0 ? (
                      invitations.results.map((invitation, index) => (
                        <div className="tr" key={index}>
                          <div className="tc tcell _10">
                            <div className="m">{index + 1}</div>
                          </div>
                          <div className="tc tcell _40">
                            <img
                              src={imageUrl}
                              loading="lazy"
                              alt=""
                              className="r-i"
                            />
                            <Link
                              to={
                                invitation?.username
                                  ? `/per-learner/learners/${invitation.username}`
                                  : '#'
                              }
                              className="tl-2 np"
                            >
                              <span className="nb">
                                {invitation?.username || '-'}
                              </span>
                              <span className="text-span-7"></span>
                            </Link>
                          </div>
                          <div className="tc tcell _40">
                            <div className="m">{invitation?.email}</div>
                          </div>
                          <div className="tc tcell w_a _10">
                            <div className="sm">
                              <div
                                className={`text-block fa_icon ${invitation.active ? 'check-circle' : 'close'}`}
                              >
                                {invitation.active ? '' : ''}
                              </div>
                            </div>
                          </div>
                          {state.activeTableInvitationType === 'Program' && (
                            <div className="tc tcell _40">
                              <div className="m">{invitation?.program_key}</div>
                            </div>
                          )}
                          {state.activeTableInvitationType === 'Course' && (
                            <div className="tc tcell _40">
                              <div className="m">{invitation?.course_id}</div>
                            </div>
                          )}
                          <div className="tc tcell _40">
                            <div className="m">{invitation?.source}</div>
                          </div>
                          <div className="tc _10">
                            {!invitation.email && !invitation.username && (
                              <div
                                data-source={invitation.source}
                                data-name="redeem"
                                className="redeem-btn popup-toggle"
                                data-type="platform"
                                onClick={() => {
                                  onRedeemClick(
                                    invitation.source,
                                    invitation.course_id ||
                                      invitation.program_key ||
                                      invitation.platform_key
                                  );
                                }}
                              >
                                {' '}
                                Redeem{' '}
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <EmptyTableDataInfographic />
                    )}
                    <TablePagination
                      setPage={setPage}
                      page={
                        state.platformInvitationMetaData.pagination.currentPage
                      }
                      pagination={state.platformInvitationMetaData.pagination}
                      isInvite={true}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, {useEffect, useState} from "react";
import Enrollments from "../../../components/Enrollments/Enrollments";
import "./EnrollmentsContentView.css"
import Descriptivepagetitle from "../../../components/Descriptivepagetitle/Descriptivepagetitle";

const EnrollmentsContentView = () => {

    return (
        <>
          <div className="p-row r-r">
            <div className="div-block-204 l">
              <div className="div-block-183">
                  <Descriptivepagetitle title={"DESCRIPTIVE DATA"}/>
              </div>
            </div>
            {/*<div className="div-block-204 r">
              <div className="div-block-183">
                  <Descriptivepagetitle isPredictive={true} title={"PREDICTIVE DATA"}/>
              </div>
            </div>*/}
          </div>
          <Enrollments></Enrollments>
        </>
    );
}
export default EnrollmentsContentView;
import React from 'react';
import { actionTypes } from '../../../views/content/InvitationsViewContent/InvitationsReducer';

const InvitationPlatformInput = ({ state, dispatch }) => {
  return (
    <>
      <label htmlFor="name-2" className="text-block-139-bd-f-soc">
        {state.invitationsTab.activeTab}
      </label>
      <input
        className="text-field-3 w-input dynamic-input"
        value={state.platformKey}
        onChange={(e) => {
          dispatch({
            type: actionTypes.SET_FIELD,
            field: 'platformKey',
            value: e.target.value,
          });
        }}
        name="name-2"
        data-name="Name 2"
        placeholder={`Enter platform key for bulk ${state.invitationsTab.activeTab.toLowerCase()} invitation`}
        type="text"
        id="name-2"
      />
      <div
        className="div-block-246 custom"
        style={{ paddingLeft: '14px', paddingTop: '6px' }}
      >
        <img
          style={{ width: '20px' }}
          src="/images/key.png"
          className="text-block fa_icon mg"
          alt="platform key image"
        />
      </div>

      <label htmlFor="invite-mode" className="text-block-139-bd-f-soc">
        {state.invitationsTab.activeTab} Invite Mode
      </label>
    </>
  );
};

export default InvitationPlatformInput;

import React from "react";
import Grading from "../../../components/Grading/Grading";
import "./GradingViewContent.css"
import Descriptivepagetitle from "../../../components/Descriptivepagetitle/Descriptivepagetitle";

const is_ai_enabled = process.env.REACT_APP_IBL_ENABLE_AI !== 'false';

const GradingViewContent = () => {
    return (
        <>
          <div className="p-row r-r skill">
            <div className="div-block-204 l skill">
              <div className="div-block-183 w_i">
                  <Descriptivepagetitle title={"DESCRIPTIVE DATA"}/>
              </div>
            </div>
              {
                  is_ai_enabled && (
                      <div className="div-block-204 r skill">
                          <div className="div-block-183 w_i">
                              <Descriptivepagetitle isPredictive={true} title={"PREDICTIVE DATA"}/>
                          </div>
                      </div>

                  )
              }
          </div>
            <Grading></Grading>
        </>

    );
}

export default GradingViewContent;